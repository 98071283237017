import React from 'react'
import classNames from 'classnames'

import Layout from '../components/layout'
import Parallax from '../components/parallax'
import Map from '../components/Maps'

const ContactPage = () => {
  const contactClass = classNames({
    contact: true,
  })
  return (
    <Layout title="Contact us">
      <Parallax
        background="contact.jpg"
        head="ติดต่อเราได้ที่"
        img="QR-LINE.png"
        email="sales-aw@awsecuresoft.com"
        contact={contactClass}
      />
      <div
        className="section section-presentation"
        data-aos="fade-up"
        data-aos-duration="2000"
      >
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="description">
                <h3 className="header-title">
                  บริษัท เอดับเบิ้ลยู ซิเคียว ซอฟต์ จำกัด (AW secure soft
                  company limited)
                </h3>
                <p>เลขประจำตัวผู้เสียภาษี 0125564000161</p>
                <p>
                  101/56 หมู่ 4 ตำบลปลายบาง อำเภอบางกรวย จังหวัดนนทบุรี 11130
                </p>
              </div>
            </div>
            <div className="col-md-12">
              <Map />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default ContactPage
